import { trackMixpanelEvent } from '../Mixpanel';
import { Dict } from 'mixpanel-browser';

const EVENT_ACTION = {
    EXTERNAL_BOOKING_EVENT_LISTING: 'externalBooking.pageView.eventListing', // extraParams: { groupId, trainerId, eventTypeId }
    EXTERNAL_BOOKING_CALENDAR: 'externalBooking.pageView.calendar', // extraParams: { groupId, trainerId, eventTypeId }
    EXTERNAL_BOOKING_DETAILS_FORM: 'externalBooking.pageView.detailsForm', // extraParams: { groupId, trainerId, eventTypeId }
    EXTERNAL_BOOKING_COMPLETE_BOOKING: 'externalBooking.completeBooking', // extraParams: { businessID, trainerID, eventTypeId }
    EXTERNAL_BOOKING_CANCEL: 'externalBooking.pageView.cancel', // extraParams: { groupId, trainerId, eventGUID }
    EXTERNAL_BOOKING_COMPLETE_CANCEL: 'externalBooking.completeCancel', // extraParams: { businessID, trainerID, eventGUID }
};

/**
 * Throw an analytics tracking event - a wrapper for the current global
 * trUsage function
 *
 * @param {string} eventAction the action to record as a string
 * @param {Object} [extraParams] additional tracking params to send
 */
const throwEvent = (eventAction: string, extraParams?: Dict) => {
    trackMixpanelEvent(eventAction, extraParams);

    if (window.g_runningLocalDev) {
        console.warn(`TrUsage Event: ${eventAction}`, JSON.stringify(extraParams, undefined, 2));
    }
};

export default { EVENT_ACTION, throwEvent };
