import { FC } from 'react';
import { Col, Divider, Flex, Row } from 'antd';
import { formatDate } from 'date-fns';
import Text from '../typography/Text';
import { convertToLocalDate, DisplayDayDateMonthFormat, DisplayTimeFormat } from '~/modules/utils/datetime';
import Button from '~/components/Button';
import Card from '~/components/Card';
import { timePicker as strings } from './constant/strings';
import useWindowMediaQuery from '~/feature/externalBooking/hooks/useWindowMediaQuery';

interface ITimePicker {
    timeSlots: string[];
    selectedDate?: Date;
    selectedTime?: string;
    onSelectTime: (time: string) => void;
}

const styles: Record<string, React.CSSProperties> = {
    divider: {
        margin: 0,
    },
    timeSlotsContainer: {
        marginTop: 32,
    },
};

const TimePicker: FC<ITimePicker> = props => {
    const { applyMinMediaQuery } = useWindowMediaQuery();
    const { selectedDate, timeSlots = [], selectedTime, onSelectTime } = props;

    const dateText = selectedDate ? formatDate(selectedDate, DisplayDayDateMonthFormat) : '';

    const renderTimeSlot = (timeSlot: string) => {
        const isSelected = selectedTime === timeSlot;
        const startTime = convertToLocalDate(timeSlot, DisplayTimeFormat);
        return (
            <Col key={timeSlot} md={6} sm={12} xs={12}>
                <Button
                    style={{ width: '100%' }}
                    type={isSelected ? 'primary' : 'default'}
                    btnColor={isSelected ? Button.color.GRAY3 : Button.color.GRAY2}
                    onClick={() => onSelectTime(timeSlot)}
                >
                    {startTime}
                </Button>
            </Col>
        );
    };

    const renderNullScreen = () => (
        <Card hideShadow>
            <Flex gap="small" vertical>
                <Text text={strings.nullScreen.text} type="secondary" center />
                <Text text={strings.nullScreen.caption} type="secondary" center fontWeight={700} />
            </Flex>
        </Card>
    );

    if (!selectedDate) {
        return null;
    }

    return (
        <Flex vertical>
            <Row gutter={[0, 14]}>
                <Col span={24} order={1}>
                    <Text text={strings.label} fontWeight={500} size="xlarge" center={applyMinMediaQuery} />
                </Col>
                <Col span={24} order={3} md={{ order: 2 }}>
                    <Divider style={styles.divider} />
                </Col>
                <Col span={24} order={2} md={{ order: 3 }}>
                    <Text text={dateText} fontWeight={300} size="xlarge" center={applyMinMediaQuery} />
                </Col>
            </Row>
            <Flex vertical gap={32} style={styles.timeSlotsContainer}>
                {timeSlots.length === 0 ? (
                    renderNullScreen()
                ) : (
                    <Row gutter={[32, 32]}>{timeSlots.map(timeslot => renderTimeSlot(timeslot))}</Row>
                )}
            </Flex>
        </Flex>
    );
};

export default TimePicker;
