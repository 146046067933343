
const COLOUR_SYSTEM = {
    YELLOW: {
        PRIMARY: '#F8F8A2',
        SECONDARY: '#FAFAB7',
    },
    RED: {
        PRIMARY: '#FF5366',
    },
    BLUE: {
        PRIMARY: '#8DC0CF',
        SECONDARY: '#B7DBE6',
    },
    GRAY: {
        WHITE: '#FFFFFF',
        BLUE_GRAY: '#F5F5F5',
        50: '#EDEDED',
        100: '#E3E5E5',
        200: '#B5B8BA', // icon color
        300: '#6E7073', // caption/secondary text color
        400: '#4D4A4A',
        500: '#241F20', // text color
        BLACK: '#151112',
    },
};

const COLOUR = {
    PRIMARY: {
        BLACK: COLOUR_SYSTEM.GRAY.BLACK,
        GRAY: COLOUR_SYSTEM.GRAY[500],
        YELLOW: COLOUR_SYSTEM.YELLOW.PRIMARY,
        WHITE: COLOUR_SYSTEM.GRAY.WHITE,
        RED: COLOUR_SYSTEM.RED.PRIMARY,
        BLUE: COLOUR_SYSTEM.BLUE.PRIMARY,
    },
    SECONDARY: {
        GRAY: COLOUR_SYSTEM.GRAY[300],
        YELLOW: COLOUR_SYSTEM.YELLOW.SECONDARY,
        BLUE: COLOUR_SYSTEM.BLUE.SECONDARY,
    },
    GRAYSCALE: {
        WHITE: COLOUR_SYSTEM.GRAY.WHITE,
        BLACK: COLOUR_SYSTEM.GRAY.BLACK,
        BLUE_GRAY: COLOUR_SYSTEM.GRAY.BLUE_GRAY,
        GRAY0: COLOUR_SYSTEM.GRAY[50],
        GRAY1: COLOUR_SYSTEM.GRAY[100],
        GRAY2: COLOUR_SYSTEM.GRAY[200],
        GRAY3: COLOUR_SYSTEM.GRAY[300],
        GRAY4: COLOUR_SYSTEM.GRAY[400],
        GRAY5: COLOUR_SYSTEM.GRAY[500],
    },
    YELLOW: COLOUR_SYSTEM.YELLOW.PRIMARY,
    RED: COLOUR_SYSTEM.RED.PRIMARY,
}

const _default = COLOUR;
export { _default as default };