import { FunctionComponent, MouseEventHandler } from 'react';
import { ICONS, ICON_COLORS } from '~/constant/icons.mjs';

export const SIZE = {
    small: 14,
    medium: 16,
    large: 20,
    xlarge: 24,
} as const;

export type Icons =
    | (typeof ICONS.filled)[keyof typeof ICONS.filled]
    | (typeof ICONS.outlined)[keyof typeof ICONS.outlined];
export type IconColors = (typeof ICON_COLORS)[keyof typeof ICON_COLORS];
export type IconSize = number | keyof typeof SIZE;

export type IconProps = {
    icon: Icons;
    color?: IconColors;
    size?: IconSize;
    className?: string;
    style?: React.CSSProperties;
    secondary?: boolean;
    disabled?: boolean;
    clickable?: boolean;
    onClick?: MouseEventHandler;
    title?: string;
};

export type FC<P> = FunctionComponent<P> & {
    icon: typeof ICONS;
    color: typeof ICON_COLORS;
    size: typeof SIZE;
};
