import Axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, RawAxiosRequestHeaders } from 'axios';

type Headers = RawAxiosRequestHeaders;
type UrlScheme = {
    readonly apiVersion: string;
    readonly httpScheme: string;
    readonly domainUrl: string;
    readonly host: string;
};
type ServiceConfig<B = undefined> = AxiosRequestConfig &
    (B extends undefined
        ? {
              body: Record<any, any>;
          }
        : {
              body: B;
          });
export type ServiceResponse<T> = AxiosResponse<T>;

const getBody = <T = undefined>(config: ServiceConfig<T>): T | undefined => config.body as T;

const __DEV__ = import.meta.env.MODE === 'development';
const showRequestLogs = false;

const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
};

const log = (item: any, tag?: any) => {
    if (__DEV__ && showRequestLogs) console.log(tag || 'RequestService', item);
    return item;
};

const logError = (error: any, tag?: any) => {
    const response = error.response ?? JSON.stringify(error, undefined, 2);
    log(response, tag);
    return error;
};

const logWithTag = (tag: any) => (item: any) => log(item, tag);
const logErrorWithTag = (tag: any) => (error: any) => Promise.reject(logError(error, tag));

export default class RequestService {
    host: string;
    httpScheme: string;
    domainUrl: string;
    apiVersion: string;
    urlScheme: UrlScheme;
    headers: RawAxiosRequestHeaders;
    axios: AxiosInstance;

    constructor(urlScheme: UrlScheme, headers = { ...defaultHeaders }) {
        this.host = urlScheme.host;
        this.httpScheme = urlScheme.httpScheme;
        this.domainUrl = urlScheme.domainUrl;
        this.apiVersion = urlScheme.apiVersion;
        this.urlScheme = urlScheme;

        this.headers = headers;
        this.axios = Axios.create({
            baseURL: this.host,
            timeout: 30000,
        });

        if (__DEV__) {
            this.axios.interceptors.request.use(
                logWithTag('RequestService config'),
                logErrorWithTag('RequestService config error'),
            );
            this.axios.interceptors.response.use(
                logWithTag('RequestService response'),
                logErrorWithTag('RequestService response error'),
            );
        }

        // Axios Error Fix
        // There is a difference in the error response given by Axios between
        // DEV and PROD and other cases my exist as well. PROD returns the entire response config while DEV only
        // returns 'error.response'. Here we are normalizing the response using the interceptor middleware.
        // Ref: https://github.com/axios/axios/issues/960
        this.axios.interceptors.response.use(
            response => {
                return response;
            },
            function (error) {
                if (error && Object.prototype.hasOwnProperty.call(error, 'response')) {
                    return Promise.reject(error.response);
                }
                return Promise.reject(error);
            },
            
        );
    }

    updateHeaders(headers: Headers): void {
        this.headers = { ...this.headers, ...headers };
    }

    removeHeader(key: string): void {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [key]: deletedHeader, ...headers } = this.headers;
        this.headers = headers;
    }

    buildHeaders(headers: Headers): Headers {
        const updatedHeaders = {
            ...this.headers,
            ...headers,
        };
        return updatedHeaders;
    }

    fetch<Resp, Req = undefined>(config: ServiceConfig<Req>): Promise<ServiceResponse<Resp>> {
        const {
            url,
            method = 'POST',
            headers = {},
            timeout = 30000,
            cancelToken,
            responseType = 'json',
            baseURL,
        } = config;
        const body = getBody<Req>(config);

        const updatedHeaders = this.buildHeaders(headers);

        return this.axios
            .request({
                url,
                method,
                headers: updatedHeaders,
                data: body,
                timeout,
                cancelToken,
                responseType,
                baseURL: baseURL || this.host,
            })
            .then(res => res)
    }

}
