import React, { useEffect } from 'react';
import { initMixpanel, setupMixpanelSession } from 'modules/Mixpanel';

const MixpanelLoader: React.FC = () => {
    useEffect(() => {
        initMixpanel(() => {
            setupMixpanelSession();
        });
        // mixpanel.set_config({ debug: true }); // Uncomment this line to enable debug mode
    }, []);
    return null;
};

export default MixpanelLoader;
