import React, { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { add, startOfDay } from 'date-fns';
import {
    AvailableTimeSlotByUTCDate,
    BookedEventDetail,
    ExternalBookingRequest,
    ExternalBookingType,
    Location,
    TrainerInfoResponse,
} from '../constants/types';
import RequestService from '~/modules/api/RequestLayer';
import { getErrorMsg } from '../modules';
import { isOutOfRange, handlesDST } from '../modules/datesHelper';
import { convertToUtc } from '~/modules/utils/datetime';
import { MAX_BOOKING_WINDOW } from '../constants';
import { getAvailableDates } from '../modules/selectors';
import { ErrorType, RequestError } from '~/modules/api/types';
import TrUsage from '~/modules/TrUsage';

export type Props = {
    children?: ReactNode;
};

interface IExternalBookingContext {
    trainerInfo?: TrainerInfoResponse;
    eventTypes?: ExternalBookingType[];
    eventType?: ExternalBookingType;
    locations: Location[];
    locationId?: number;
    availableDates?: string[];
    timeSlots?: AvailableTimeSlotByUTCDate;
    selectedDate?: Date;
    appointmentTime?: string;
    isLoading: boolean;
    error?: ErrorType;
    isBooked: boolean;
    isCanceled: boolean;
    bookingEvent?: BookedEventDetail;
    setLocationId: (newLocationID?: number) => void;
    setSelectedDate: (date: Date) => void;
    setAppointmentTime: (dateTime?: string) => void;
    completeBooking: (value: ExternalBookingRequest) => void;
    cancelBooking: (id: string) => void;
    fetchEventByEventGUID: (eventGUID: string) => void;
    setError: (error?: ErrorType) => void;
    resetBookingState: () => void;
    fetchTimeSlots: (trainerId: number, eventTypeId: number, locationId?: number) => void;
}

const initState: IExternalBookingContext = {
    trainerInfo: undefined,
    eventTypes: undefined,
    eventType: undefined,
    locationId: undefined,
    locations: [],
    availableDates: undefined,
    timeSlots: undefined,
    selectedDate: undefined,
    appointmentTime: undefined,
    isLoading: true,
    error: undefined,
    isBooked: false,
    isCanceled: false,
    bookingEvent: undefined,
    setLocationId: () => null,
    setSelectedDate: () => null,
    setAppointmentTime: () => null,
    completeBooking: () => null,
    cancelBooking: () => null,
    fetchEventByEventGUID: () => null,
    setError: () => null,
    resetBookingState: () => null,
    fetchTimeSlots: () => null,
};

export const ExternalBookingContext = createContext<IExternalBookingContext>(initState);

const ExternalBookingProvider: FC<Props> = ({ children }) => {
    const { groupId, trainerId, eventTypeId } = useParams();

    const [bookingContext, setBookingContext] = useState<IExternalBookingContext>({
        ...initState,
        setLocationId,
        setSelectedDate,
        setAppointmentTime,
        completeBooking,
        resetBookingState,
        cancelBooking,
        fetchEventByEventGUID,
        fetchTimeSlots,
        setError,
    });

    const { eventType } = bookingContext;

    useEffect(() => {
        if (trainerId) {
            fetchTrainerInfo(parseInt(trainerId));
            if (eventTypeId) {
                fetchEventType(parseInt(trainerId), parseInt(eventTypeId));
            } else {
                fetchEventTypes(parseInt(trainerId));
            }
        }
    }, [trainerId, eventTypeId]);

    useEffect(() => {
        if (trainerId && eventType && !eventType?.isVirtual) {
            fetchLocationList(parseInt(trainerId));
        }
    }, [eventType]);

    const fetchTrainerInfo = async (id: number) => {
        try {
            setIsLoading(true);
            const { data } = await RequestService.ExternalBookingService.getTrainerInfo(id);
            setBookingContext(prevContext => ({
                ...prevContext,
                trainerInfo: data,
                isLoading: false,
            }));
        } catch (error: unknown) {
            const requestError = error as RequestError;
            setError(requestError?.data);
        }
    };

    const fetchEventTypes = async (id: number) => {
        try {
            setIsLoading(true);
            const {
                data: { appointmentTypes },
            } = await RequestService.ExternalBookingService.getEventTypeList(id);
            setBookingContext(prevContext => ({
                ...prevContext,
                eventTypes: appointmentTypes,
                isLoading: false,
            }));
        } catch (error: unknown) {
            const requestError = error as RequestError;
            setError(requestError?.data);
        }
    };

    const fetchLocationList = async (id: number) => {
        try {
            setIsLoading(true);
            const { data } = await RequestService.ExternalBookingService.getLocationList(id);
            setBookingContext(prevContext => ({
                ...prevContext,
                locations: data.locations,
                isLoading: false,
            }));
            setLocationId(data.locations[0]?.locationID);
        } catch (error: unknown) {
            const requestError = error as RequestError;
            setError(requestError?.data);
        }
    };

    const fetchEventType = async (trainerID: number, appointmentTypeID: number) => {
        try {
            setIsLoading(true);
            const {
                data: { appointmentType },
            } = await RequestService.ExternalBookingService.getEventType(trainerID, appointmentTypeID);
            setBookingContext(prevContext => ({
                ...prevContext,
                eventType: appointmentType,
                isLoading: false,
            }));
        } catch (error: unknown) {
            const requestError = error as RequestError;
            setError(requestError?.data);
        }
    };

    const fetchAvailableTimeslots = async (
        trainer: number,
        apptType: number,
        startTime: string,
        endTime: string,
        locationID?: number,
    ) => {
        try {
            setIsLoading(true);
            const dates = handlesDST(startTime, endTime);
            const promises = dates.map(async date => {
                const { data } = await RequestService.ExternalBookingService.getAvailableTimeSlots(
                    trainer,
                    apptType,
                    date.startDateTime,
                    date.endDateTime,
                    locationID,
                );
                return data.dailyTimeslots;
            });
            // Wait for all the API calls to complete and gather the results
            const results = await Promise.all(promises);
            // Merge all the results into a single object
            const dailyTimeSlots = results.reduce(
                (acc, dailyTimeslots) => ({
                    ...acc,
                    ...dailyTimeslots,
                }),
                {},
            );
            setAvailableTimeSlots(dailyTimeSlots);
        } catch (error: unknown) {
            const requestError = error as RequestError;
            setError(requestError?.data);
        }
    };

    const completeBookingEvent = async (data: ExternalBookingRequest) => {
        let hasError = false;
        try {
            setIsLoading(true);
            const response = await RequestService.ExternalBookingService.completeBooking(data);
            if (response.data.code === 0) {
                setBookingContext(prevContext => ({
                    ...prevContext,
                    isBooked: true,
                    setIsLoading: false,
                }));
            }
        } catch (error: unknown) {
            const requestError = error as RequestError;
            setError(requestError?.data);
            hasError = true;
        }
        TrUsage.throwEvent(TrUsage.EVENT_ACTION.EXTERNAL_BOOKING_COMPLETE_BOOKING, {
            groupId,
            trainerId,
            eventTypeId,
            error: hasError,
        });
    };

    const cancelBookingEvent = async (id: string) => {
        let hasError = false;
        try {
            setIsLoading(true);
            const response = await RequestService.ExternalBookingService.cancelBooking(id);
            if (response.data.code === 0) {
                setBookingContext(prevContext => ({
                    ...prevContext,
                    isCanceled: true,
                    isLoading: false,
                }));
            }
        } catch (error: unknown) {
            const requestError = error as RequestError;
            setError(requestError?.data);
            hasError = true;
        }
        TrUsage.throwEvent(TrUsage.EVENT_ACTION.EXTERNAL_BOOKING_COMPLETE_CANCEL, {
            eventGUID: id,
            error: hasError,
        });
    };

    const fetchBookedEventByEventGUID = async (id: string) => {
        try {
            setIsLoading(true);
            const response = await RequestService.ExternalBookingService.getBookingByGUID(id);
            if (response.data.code === 0 && response.data.externalBooking) {
                setBookingContext(prevContext => ({
                    ...prevContext,
                    bookingEvent: response.data.externalBooking,
                    isLoading: false,
                }));
            }
        } catch (error: unknown) {
            const requestError = error as RequestError;
            setError(requestError?.data);
        }
    };

    function setLocationId(id?: number) {
        setBookingContext(prevContext => ({
            ...prevContext,
            locationId: id,
        }));
    }

    function setAvailableTimeSlots(dailyTimeSlots?: AvailableTimeSlotByUTCDate) {
        const availableDates = getAvailableDates(dailyTimeSlots);
        const firstDate = availableDates.length > 0 ? new Date(availableDates[0]) : undefined;
        const isValidDate = firstDate && !isOutOfRange(firstDate);
        setBookingContext(prevContext => ({
            ...prevContext,
            availableDates,
            timeSlots: dailyTimeSlots,
            selectedDate: isValidDate ? firstDate : undefined,
            isLoading: false,
        }));
    }

    function setSelectedDate(date: Date) {
        setBookingContext(prevContext => ({
            ...prevContext,
            selectedDate: startOfDay(date),
            appointmentTime: undefined,
        }));
    }

    function setAppointmentTime(dateTime?: string) {
        setBookingContext(prevContext => ({
            ...prevContext,
            appointmentTime: dateTime,
        }));
    }

    function setIsLoading(value: boolean) {
        setBookingContext(prevContext => ({
            ...prevContext,
            isLoading: value,
        }));
    }

    function setError(error?: ErrorType) {
        const newError = error ? { message: getErrorMsg(error.code), code: error.code } : undefined;
        setBookingContext(prevContext => ({
            ...prevContext,
            error: newError,
            isLoading: false,
        }));
    }

    function completeBooking(value: ExternalBookingRequest) {
        completeBookingEvent(value);
    }

    function resetBookingState() {
        setBookingContext(prevContext => ({
            ...prevContext,
            eventType: undefined,
            locationId: undefined,
            availableDates: undefined,
            timeSlots: undefined,
            selectedDate: undefined,
            appointmentTime: undefined,
            isBooked: false,
            isCanceled: false,
            error: undefined,
        }));
    }

    function cancelBooking(id: string) {
        cancelBookingEvent(id);
    }

    function fetchEventByEventGUID(id: string) {
        fetchBookedEventByEventGUID(id);
    }

    function fetchTimeSlots(trainerID: number, typeID: number, locationID?: number) {
        const startTime = convertToUtc(startOfDay(new Date()));
        const endTime = convertToUtc(startOfDay(add(new Date(), { months: MAX_BOOKING_WINDOW })));
        fetchAvailableTimeslots(trainerID, typeID, startTime, endTime, locationID);
    }

    return <ExternalBookingContext.Provider value={{ ...bookingContext }}>{children}</ExternalBookingContext.Provider>;
};

export function useExternalBookingContext(): IExternalBookingContext {
    const context = useContext<IExternalBookingContext>(
        ExternalBookingContext as React.Context<IExternalBookingContext>,
    );
    if (!context) {
        throw new Error('useExternalBookingContext must be used under IExternalBookingContext');
    }
    return context;
}

export default ExternalBookingProvider;
