import useWindowSize from 'modules/hooks/useWindowSize';

const SMALL_DEVICE_WIDTH = 767;
const useWindowMediaQuery = (): { applyMinMediaQuery: boolean; width: number } => {
    const [width] = useWindowSize();
    const applyMinMediaQuery = width <= SMALL_DEVICE_WIDTH;
    return { applyMinMediaQuery, width };
};

export default useWindowMediaQuery;
