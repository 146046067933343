import { RouterProvider } from 'react-router';
import { ConfigProvider } from 'antd';
import router from './routes';
import config from './constant/theme';
import locale from 'antd/es/locale/en_GB';

function App() {
    return (
        <ConfigProvider locale={locale} theme={config}>
            <RouterProvider router={router} />
        </ConfigProvider>
    );
}

export default App;
