import { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import { useExternalBookingContext } from '~/feature/externalBooking/context/ExternalBookingContext';

const defaultTitle = 'Trainerize.me';

const PageTitle: FC = () => {
    const { trainerId, eventTypeId, eventGUID } = useParams();
    const { trainerInfo, eventType, bookingEvent } = useExternalBookingContext();

    const [title, setTitle] = useState<string>(defaultTitle);

    useEffect(() => {
        if (trainerId && trainerInfo) {
            setTitle(`${trainerInfo.firstName} ${trainerInfo.lastName} | ${defaultTitle}`);
        }
        if (eventTypeId && eventType) {
            setTitle(`${eventType.name} | ${defaultTitle}`);
        }
        if (eventGUID && bookingEvent) {
            setTitle(`${bookingEvent.appointmentTypeName} | ${defaultTitle}`);
        }
    }, [trainerInfo, eventType, trainerId, eventTypeId, eventGUID, bookingEvent]);

    return (
        <Helmet>
            <title>{title}</title>
        </Helmet>
    );
};

export default PageTitle;
