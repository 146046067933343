import { CSSProperties, FC } from 'react';
import { Flex } from 'antd';
import COLOUR from '~/constant/colour.mjs';
import Text from '../typography/Text';

const styles: Record<string, CSSProperties> = {
    container: {
        margin: '16px -32px -32px 32px',
    },
    wrapper: {
        padding: '6px 8px',
        borderRadius: '9px 0 9px 0',
        boxShadow: '2px 0px 10px 0px rgba(0, 0, 0, 0.10)',
        backgroundColor: COLOUR.PRIMARY.WHITE,
    },
    logo: {
        height: 17,
    },
};

const FooterLogo: FC = () => {
    return (
        <Flex justify="flex-end" style={styles.container}>
            <Flex gap={4} align="center" justify="center" style={styles.wrapper}>
                <Text text="Powered By" type="secondary" size={8} />
                <img
                    src="https://file.trainerize.com/assets/tz/common-assets/logos/abc-trainerize-horizontal-dark.svg"
                    alt="abcTrainerize"
                    style={styles.logo}
                />
            </Flex>
        </Flex>
    );
};

export default FooterLogo;
