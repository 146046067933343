import { Flex } from 'antd';
import { FC } from 'react';
import Alert from '~/components/Alert';
import Text from '~/components/typography/Text';
import { invalidScreen as strings } from './constants/strings';

interface IInvalidScreen {
    alertText?: string;
}

const InvalidScreen: FC<IInvalidScreen> = ({ alertText }) => {
    return (
        <>
            {alertText && <Alert message={alertText} type="error" banner showIcon={false} />}
            <Flex style={{ marginTop: '20%' }} justify="center">
                <Text text={strings.notFound} size="xlarge" />
            </Flex>
        </>
    );
};

export default InvalidScreen;
