import React from 'react';
import { Typography } from 'antd';
import { TextProps } from 'antd/es/typography/Text';

import { parseWithUrls } from '~/modules/utils/parsing';
import SIZE from '~/constant/typography';

const { Text: AntText, Paragraph } = Typography;

interface IText extends TextProps {
    text: string | JSX.Element;
    size?: number | keyof typeof SIZE;
    fontWeight?: number;
    style?: React.CSSProperties;
    inline?: boolean;
    useSpan?: boolean;
    parseUrls?: boolean;
    center?: boolean;
}

const styles: Record<string, React.CSSProperties> = {
    defaultMargin: { margin: 0 },
};

const Text: React.FC<IText> = ({
    text,
    fontWeight,
    size = 'medium',
    style = {},
    useSpan = false,
    parseUrls = false,
    center = false,
    ...rest
}) => {
    const textSize = typeof size === 'string' ? SIZE[size] : size;
    const textStyle: React.CSSProperties = {
        ...styles.defaultMargin,
        ...style,
        ...(fontWeight ? { fontWeight } : {}),
        ...(size ? { fontSize: textSize } : {}),
        ...(center ? { textAlign: 'center' } : {}),
    };

    const parsedText =
        parseUrls && typeof text === 'string' ? <span dangerouslySetInnerHTML={parseWithUrls(text)} /> : text;

    return useSpan ? (
        <AntText style={textStyle} {...rest}>
            {parsedText}
        </AntText>
    ) : (
        <Paragraph style={textStyle} {...rest}>
            {parsedText}
        </Paragraph>
    );
};

export default Text;
