import COLOUR from './colour.mjs';

const ICONS_FILLED = {
    location: 'filled/location',
    phone: 'filled/phone',
    appointments: 'filled/appointments',
    duration: 'filled/duration',
    inPerson: 'filled/inPersonExternal',
};

const ICONS_OUTLINED = {
    leftArrow: 'outline/backArrow',
    rightArrow: 'outline/nextArrow',
    upArrow: 'outline/upArrow',
    downArrow: 'outline/downArrow',
    arrowBack: 'outline/arrow-back',
}

const ICONS = {
    filled: ICONS_FILLED,
    outlined: ICONS_OUTLINED,
};

// Regularly used icon colors
const ICON_COLORS = {
    black: COLOUR.PRIMARY.BLACK,
    white: COLOUR.PRIMARY.WHITE,
    lightGray: COLOUR.GRAYSCALE.GRAY1,
    blueGray: COLOUR.GRAYSCALE.BLUE_GRAY,
    yellow: COLOUR.YELLOW,
    red: COLOUR.RED,
    ...COLOUR.GRAYSCALE,
};

export { ICONS, ICON_COLORS };
export default {
    icon: ICONS,
    color: ICON_COLORS,
};
