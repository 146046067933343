import { useEffect } from 'react';
import { useParams } from 'react-router';
import { Flex } from 'antd';
import LocationSelect from '~/feature/externalBooking/ui/LocationSelect';
import { useExternalBookingContext } from '~/feature/externalBooking/context/ExternalBookingContext';
import DatePicker from '~/components/DatePicker';
import TimePicker from '~/components/TimePicker';
import { getAvailableTimeSlotsByLocalDate } from '~/feature/externalBooking/modules/selectors';
import TrUsage from '~/modules/TrUsage';

const styles: Record<string, React.CSSProperties> = {
    container: {
        paddingBottom: 24,
    },
};

type Props = {
    onSelectTime: () => void;
};

const EventTimeForm: React.FC<Props> = ({ onSelectTime }) => {
    const { groupId, trainerId, eventTypeId } = useParams();
    const {
        isLoading,
        eventType,
        availableDates,
        timeSlots,
        selectedDate,
        setSelectedDate,
        appointmentTime,
        setAppointmentTime,
        locationId,
        fetchTimeSlots,
    } = useExternalBookingContext();
    const showLocationSelect = eventType && !eventType.isVirtual;
    const availableTimeSlots =
        timeSlots && selectedDate ? getAvailableTimeSlotsByLocalDate(timeSlots, selectedDate) : [];

    useEffect(() => {
        TrUsage.throwEvent(TrUsage.EVENT_ACTION.EXTERNAL_BOOKING_CALENDAR, { groupId, trainerId, eventTypeId });
    }, [groupId, trainerId, eventTypeId]);

    useEffect(() => {
        if (trainerId && eventType && (eventType.isVirtual || locationId)) {
            fetchTimeSlots(parseInt(trainerId), eventType.appointmentTypeID, locationId);
        }
    }, [eventType, locationId, fetchTimeSlots]);

    const handleSelectTime = (time: string) => {
        onSelectTime();
        setAppointmentTime(time);
    };

    return (
        <Flex gap="large" vertical style={styles.container}>
            {showLocationSelect && <LocationSelect />}
            <DatePicker
                isLoading={isLoading}
                selectedDate={selectedDate}
                onSelectDate={setSelectedDate}
                highlightDates={availableDates}
            />
            <TimePicker
                selectedDate={selectedDate}
                timeSlots={availableTimeSlots}
                selectedTime={appointmentTime}
                onSelectTime={handleSelectTime}
            />
        </Flex>
    );
};

export default EventTimeForm;
