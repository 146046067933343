import { convertToLocalDate, convertToUtc } from '~/modules/utils/datetime';
import { AvailableTimeSlotByUTCDate, Location } from '../../externalBooking/constants/types';

export const getLocationByID = (locations: Location[], id?: number): Location | undefined => {
    if (locations.length === 0 || !id) {
        return undefined;
    }
    return locations.find(location => location.locationID === id);
};

export const getAvailableDates = (availableTimeSlots?: AvailableTimeSlotByUTCDate): string[] => {
    if (!availableTimeSlots) {
        return [];
    }
    return Object.entries(availableTimeSlots)
        .filter(([, { availableCount }]) => availableCount > 0)
        .map(([utcTime]) => convertToLocalDate(utcTime));
};

export const getAvailableTimeSlotsByLocalDate = (
    availableTimeSlots: AvailableTimeSlotByUTCDate,
    date: Date,
): string[] => {
    // key to get the timeslots for the selected date, is the local start of the day in UTC
    const utcDate = convertToUtc(date);
    const timeSlots = availableTimeSlots?.[utcDate]?.timeslots
        ? [...new Set(availableTimeSlots[utcDate].timeslots)]
        : [];
    return timeSlots;
};
