import React, { useEffect } from 'react';
import { Flex } from 'antd';
import useWindowMediaQuery from '~/feature/externalBooking/hooks/useWindowMediaQuery';
import { useParams } from 'react-router';
import BookingResult from '~/feature/externalBooking/ui/eventTypeForm/BookingResult';
import CancelForm from '~/feature/externalBooking/ui/CancelForm';
import { useExternalBookingContext } from '~/feature/externalBooking/context/ExternalBookingContext';
import Loader from '~/components/Loader';
import { formatBookingEventToEvent } from '~/feature/externalBooking/modules';
import InvalidScreen from '~/components/InvalidScreen';
import { invalidScreen as invalidScreenStrings } from '~/components/InvalidScreen/constants/strings';
import TrUsage from '~/modules/TrUsage';

const styles: Record<string, React.CSSProperties> = {
    container: {
        padding: '70px 0px',
    },
    mobileContainer: {
        padding: '24px 0px',
    },
    card: {
        maxWidth: 900,
    },
    cancelTxt: {
        margin: '30px 0',
    },
};

const EventCancelPage: React.FC = () => {
    const { applyMinMediaQuery } = useWindowMediaQuery();
    const { eventGUID } = useParams();
    const { cancelBooking, isCanceled, error, bookingEvent, isLoading, fetchEventByEventGUID } =
        useExternalBookingContext();
    const event = formatBookingEventToEvent(bookingEvent);

    useEffect(() => {
        if (eventGUID) {
            fetchEventByEventGUID(eventGUID);
            TrUsage.throwEvent(TrUsage.EVENT_ACTION.EXTERNAL_BOOKING_CANCEL, { eventGUID });
        }
    }, [eventGUID, fetchEventByEventGUID]);

    const handleCancelEvent = async () => {
        if (eventGUID) {
            cancelBooking(eventGUID);
        }
    };

    if (!isLoading && (error || !event)) {
        return <InvalidScreen alertText={invalidScreenStrings.invalidBookingLink} />;
    }

    return (
        <Flex
            gap="large"
            align="center"
            vertical
            style={applyMinMediaQuery ? styles.mobileContainer : styles.container}
        >
            {isLoading && <Loader fullScreen />}
            {isCanceled ? (
                <BookingResult type="cancel" event={event} />
            ) : (
                <CancelForm handleCancelEvent={handleCancelEvent} error={error} event={event} />
            )}
        </Flex>
    );
};

export default EventCancelPage;
