export const addOpacityToHex = (hex: string, opacity: number): string => {
    hex = hex.replace('#', '');

    // Convert the hex to RGB values
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    // Clamp the opacity to the range [0, 1]
    opacity = Math.min(Math.max(opacity, 0), 1);

    // Convert opacity to a two-digit hex value (255 is 100% opacity)
    const alpha = Math.round(opacity * 255)
        .toString(16)
        .padStart(2, '0');

    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}${alpha}`;
};
