const VERSION = 'v2';
const HTTP_SCHEME = 'https';
const INTERNAL_DOMAIN_URL = window.g_domain_me;
const API_URL_DEV = `${HTTP_SCHEME}://www.${INTERNAL_DOMAIN_URL}/${VERSION}/api`;
const URL_SCHEME = {
    apiVersion: VERSION,
    httpScheme: HTTP_SCHEME,
    domainUrl: INTERNAL_DOMAIN_URL,
    host: API_URL_DEV,
};

export default URL_SCHEME;
