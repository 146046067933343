import { format } from 'date-fns';
import { strings } from '../constants/strings';
import Icon from '~/components/Icon';
import { BookedEventDetail, ErrorCode, ExternalBookingType, Location, TrainerInfoResponse } from '../constants/types';
import { PhoneNumberUtil, PhoneNumberFormat as PNF } from 'google-libphonenumber';
import { BookingFieldType } from '~/pages/BookingPage/EventFormPage';
import { BookingResultEvent } from '../ui/eventTypeForm/BookingResult';
import { shortenOClock } from '~/modules/utils/datetime';

export const getFormatStr = (isPhoneCall: boolean) => {
    return isPhoneCall ? strings.phoneCall : strings.inPerson;
};

export const getFormatIcon = (isPhoneCall: boolean) => {
    return isPhoneCall ? Icon.icon.filled.phone : Icon.icon.filled.inPerson;
};

/**
 * @returns format startTime and endTime. ex) 10 AM - 11 AM, Friday, 4 April, 2024
 */
export const getTimeFormatStr = (startTime: string, endTime: string) => {
    const formattedStartTime = shortenOClock(startTime);
    const formattedEndTime = shortenOClock(endTime);
    const formattedDate = format(startTime, 'EEEE, d MMMM, yyyy');

    const formattedString = `${formattedStartTime} - ${formattedEndTime}, ${formattedDate}`;
    return formattedString;
};

export const getErrorMsg = (errorCode?: ErrorCode) => {
    switch (errorCode) {
        case ErrorCode.accountAlreadyExist:
            return strings.error.accountAlreadyExist;
        case ErrorCode.appointmentConflict:
            return strings.error.appointmentConflict;
        case ErrorCode.recaptcha:
            return strings.error.recaptcha;
        default:
            return strings.error.default;
    }
};

export const formatAddress = (location: Location) => {
    const { address1, address2, city, state, country, zipCode, name } = location;
    const address = [address1, address2, city, state, country, zipCode].filter(Boolean).join(', ');
    return address === '' ? name : address;
};

/**
 * Get an instance of @type {libphonenumber.PhoneNumber} with country code and national phone number.
 * @param countryCode
 * @param nationalPhoneNumber
 * @returns @type {libphonenumber.PhoneNumber} when the phone number is valid, @type {null} when the phone number is not valid
 */
const phoneUtil = PhoneNumberUtil.getInstance();
const parsePhoneNumber = (countryCode: string, nationalPhoneNumber: string): libphonenumber.PhoneNumber | null => {
    try {
        return phoneUtil.parse(`${countryCode}${nationalPhoneNumber}`);
    } catch {
        return null;
    }
};

export const formatPhoneNumber = ({ countryCode, nationalPhoneNumber }: BookingFieldType['clientPhone']) => {
    const parsedNumber = parsePhoneNumber(countryCode, nationalPhoneNumber);
    // Expecting a successful parse in any case because the phone data should be validated before submit
    if (parsedNumber) {
        return phoneUtil.format(parsedNumber, PNF.E164);
    }
    return '';
};

export const phoneValidator = (_: unknown, { countryCode, nationalPhoneNumber }: BookingFieldType['clientPhone']) => {
    const parsedNumber = parsePhoneNumber(countryCode, nationalPhoneNumber);
    if (parsedNumber && phoneUtil.isValidNumber(parsedNumber)) {
        return Promise.resolve();
    }
    return Promise.reject(strings.form.validation.validPhone);
};

export const formatBookingEventToEvent = (bookingEvent?: BookedEventDetail): BookingResultEvent | undefined => {
    if (bookingEvent) {
        const {
            trainerFirstName,
            trainerLastName,
            appointmentTypeName,
            appointmentTypeDuration,
            appointmentTypeActionInfo,
            appointmentTypeIsVirtual,
            appointmentTypeDescription,
            locationName,
            startDate,
        } = bookingEvent;
        return {
            trainerInfo: { firstName: trainerFirstName, lastName: trainerLastName },
            eventType: {
                name: appointmentTypeName,
                duration: appointmentTypeDuration,
                actionInfo: appointmentTypeActionInfo,
                isVirtual: appointmentTypeIsVirtual,
                description: appointmentTypeDescription,
            },
            locationName: locationName,
            startDate: startDate,
        };
    }
    return undefined;
};

export const formatFormEventToEvent = (
    eventType?: ExternalBookingType,
    trainerInfo?: TrainerInfoResponse,
    appointmentTime?: string,
    locationName?: string,
): BookingResultEvent | undefined => {
    if (!eventType || !appointmentTime || !trainerInfo) {
        return undefined;
    }
    return {
        trainerInfo: { firstName: trainerInfo.firstName, lastName: trainerInfo.lastName },
        eventType: {
            name: eventType.name,
            duration: eventType.duration,
            actionInfo: eventType.actionInfo,
            isVirtual: eventType.isVirtual,
            description: eventType.description,
        },
        locationName,
        startDate: appointmentTime,
    };
};

export const emailValidator = (_: unknown, email: BookingFieldType['clientEmail']) => {
    const reg = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z]{2,}$/;
    const isValid = email.match(reg);
    if (isValid) {
        return Promise.resolve();
    }
    return Promise.reject(strings.form.validation.validEmail);
};
