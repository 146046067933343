import React from 'react';
import { Outlet } from 'react-router';
import MixpanelLoader from './MixpanelLoader';

const BaseRoute: React.FC = () => {
    return (
        <>
            <MixpanelLoader />
            <Outlet />
        </>
    );
};

export default BaseRoute;
