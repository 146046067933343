import { Outlet } from 'react-router';
import PageTitle from '~/components/PageTitle';
import ExternalBookingProvider from '~/feature/externalBooking/context/ExternalBookingContext';

// TODO remove
// example of the iframe:
{
    /* <iframe
        id="widget"
        src={
          "http://localhost:3000/app/booking/123/456/12?data=eyJhY2NlbnRDb2xvciI6IiNGRjUzNjYifQ=="
        }
    ></iframe>
*/
}

const BookingOutlet = () => {
    return (
        <ExternalBookingProvider>
            <PageTitle />
            <Outlet />
        </ExternalBookingProvider>
    );
};

export default BookingOutlet;
