/**
 *
 * @param {string} text Text with urls to parse
 * @returns {{__html: string}} https://reactjs.org/docs/dom-elements.html
 */
export const parseWithUrls = (text: string): {__html: string} => {
    const regex = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/gi;
    const parsedText = text.replace(regex, (url) => {
        return `<a href="${url}" target="_blank">${url}</a>`;
    });
    return { __html: parsedText };
};