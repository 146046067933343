import { Input, Space } from 'antd';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { useMemo, useState } from 'react';
import { BookingFieldType } from '~/pages/BookingPage/EventFormPage';

export type PhoneValue = BookingFieldType['clientPhone'];

type Props = {
    value?: PhoneValue;
    onChange?: (value: PhoneValue) => void;
};

const phoneUtil = PhoneNumberUtil.getInstance();

// After wrapped by Form.Item with name property, value and onChange props will be added to form controls
const PhoneNumberInput: React.FC<Props> = ({ value = {}, onChange }) => {
    const localDeviceCountryCode: string | undefined = useMemo(() => {
        const locale = Intl.DateTimeFormat().resolvedOptions().locale;
        const regionCode = locale.split('-')[1];
        const result = phoneUtil.getCountryCodeForRegion(regionCode);
        if (result > 0) {
            return `+${result}`;
        } else {
            return undefined;
        }
    }, []);

    const [phoneCountryCode, setPhoneCountryCode] = useState<string>(localDeviceCountryCode ?? '+');
    const [phoneNational, setPhoneNational] = useState<string>('');

    const triggerChange = (newValue: Partial<PhoneValue>) => {
        onChange?.({
            countryCode: phoneCountryCode,
            nationalPhoneNumber: phoneNational,
            ...value,
            ...newValue,
        });
    };

    const onCountryCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        if (newValue.length > 0 && newValue.length <= 4 && newValue[0] === '+') {
            setPhoneCountryCode(newValue);
            triggerChange({ countryCode: newValue });
        } else if (newValue.length < 1 || newValue[0] !== '+') {
            setPhoneCountryCode('+');
            triggerChange({ countryCode: '+' });
        }
    };

    const onNationalPhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setPhoneNational(newValue);
        triggerChange({ nationalPhoneNumber: newValue });
    };

    return (
        <Space.Compact>
            <Input value={phoneCountryCode} onChange={onCountryCodeChange} style={{ width: 120 }} />
            <Input value={phoneNational} onChange={onNationalPhoneNumberChange} />
        </Space.Compact>
    );
};

export default PhoneNumberInput;
