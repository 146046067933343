import React from 'react';
import { Flex } from 'antd';
import Text from '~/components/typography/Text';
import Title from '~/components/typography/Title';
import { strings } from '~/feature/externalBooking/constants/strings';
import useWindowMediaQuery from '~/feature/externalBooking/hooks/useWindowMediaQuery';
import EventInfo from '../EventInfo';
import Card from '~/components/Card';
import { ExternalBookingType } from '~/feature/externalBooking/constants/types';
import FooterLogo from '~/components/FooterLogo';

const styles: Record<string, React.CSSProperties> = {
    card: {
        maxWidth: 900,
    },
    container: {
        minWidth: 600,
    },
};

export type BookingResultEvent = {
    trainerInfo: { firstName: string; lastName: string };
    eventType: Omit<ExternalBookingType, 'appointmentTypeID'>;
    startDate: string;
    locationName?: string;
};

type Props = {
    type: 'booking' | 'cancel';
    event?: BookingResultEvent;
};

const BookingResult: React.FC<Props> = ({ type, event }) => {
    const { applyMinMediaQuery } = useWindowMediaQuery();
    const { eventType, trainerInfo, startDate, locationName } = event || {};
    const { title } = strings.result[type];
    const trainerName = `${trainerInfo?.firstName} ${trainerInfo?.lastName}`;
    const message = type === 'booking' ? strings.result[type].message : strings.result[type].message(trainerName);

    if (!eventType || !trainerInfo) {
        return null;
    }
    return (
        <Flex gap="large" align="center" vertical>
            <Title text={title} level={2} />
            <Text text={message} size="large" center={!applyMinMediaQuery} />
            <Card size="default" style={{ ...styles.card, ...(applyMinMediaQuery ? undefined : styles.container) }}>
                {eventType && trainerInfo && (
                    <EventInfo
                        appointmentType={eventType}
                        trainerName={trainerName}
                        startTime={startDate}
                        locationName={locationName}
                        isCancel={type === 'cancel'}
                    />
                )}
                <FooterLogo />
            </Card>
        </Flex>
    );
};

export default BookingResult;
