import { addMinutes, format, getMinutes } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

export const APIDateTimeFormat = 'yyyy-MM-dd HH:mm:ss';
export const APIDateFormat = 'yyyy-MM-dd';

export const DisplayTimeFormat = 'h:mm a';
export const DisplayMonthYearFormat = 'MMMM yyyy';
export const DisplayDayDateMonthFormat = 'iiii, d MMMM'; // Monday, 1 January

export const convertToUtc = (inputDateTime: Date | string, dateFormat: string = APIDateTimeFormat): string => {
    const dateTime = typeof inputDateTime === 'string' ? new Date(inputDateTime) : inputDateTime;
    return formatInTimeZone(dateTime, 'UTC', dateFormat);
};

export const convertToLocalDate = (inputDateTime: Date | string, dateFormat: string = APIDateTimeFormat): string => {
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // if the format of the inputDateTime is '2025-02-04 10:00:00', we need to emphasize that it is in UTC
    const utcDate = typeof inputDateTime === 'string' ? utc(inputDateTime) : inputDateTime;
    return formatInTimeZone(utcDate, localTimeZone, dateFormat);
};

export const getEndDateTime = (
    startDateTime: Date | string,
    duration: number,
    dateFormat: string = APIDateTimeFormat,
): string => {
    const startDate = typeof startDateTime === 'string' ? new Date(startDateTime) : startDateTime;
    const endDateTime = addMinutes(startDate, duration);
    return format(endDateTime, dateFormat);
};

export const shortenOClock = (date: string) => {
    return getMinutes(date) === 0 ? format(date, 'h a') : format(date, 'h:mm a');
};

const isISODateString = (dateString: string) => {
    const isoFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|[+-]\d{2}:\d{2})?$/;
    return isoFormat.test(dateString);
};
// set date's UTC for formatted UTC date time
// e.g. API returns datetime '2025-02-04 10:00:00' which is UTC time
export const utc = (date: string) => {
    if (isISODateString(date)) {
        return new Date(date);
    }
    const utcDate = new Date(date);
    utcDate.setUTCDate(utcDate.getDate());
    utcDate.setUTCHours(utcDate.getHours());
    utcDate.setUTCMinutes(utcDate.getMinutes());
    return utcDate;
};
