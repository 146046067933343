import React from 'react';
import { Typography } from 'antd';
import { TitleProps } from 'antd/es/typography/Title';

const { Title: AntTitle } = Typography;

interface ITitle extends TitleProps {
    text: string | JSX.Element;
    style?: React.CSSProperties;
}

const styles: Record<string, React.CSSProperties> = {
    defaultMargin: { margin: 0 },
};

const Title: React.FC<ITitle> = ({ text, style = {}, ...rest }) => {
    return (
        <AntTitle style={{ ...styles.defaultMargin, ...style }} {...rest}>
            {text}
        </AntTitle>
    );
};

export default Title;
