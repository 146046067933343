import type { ThemeConfig } from 'antd';
import COLOUR from '~/constant/colour.mjs';

// global token here
const typography: ThemeConfig['token'] = {
    fontFamily: '\'Poppins\', Helvetica, Arial, sans-serif',

    // font color
    colorText: COLOUR.PRIMARY.GRAY,
    colorTextSecondary: COLOUR.PRIMARY.GRAY,
    colorTextTertiary: COLOUR.SECONDARY.GRAY,
    colorTextDisabled: COLOUR.GRAYSCALE.GRAY2,
    colorTextLightSolid: COLOUR.PRIMARY.WHITE,

    // font size
    fontSizeHeading1: 32,
    fontSizeHeading2: 30,
    fontSizeHeading3: 24,
    fontSizeHeading4: 18,
    fontSizeHeading5: 14,
    fontWeightStrong: 500,
    lineHeightHeading1: 1.3,
    lineHeightHeading2: 1.3,
    lineHeightHeading3: 1.3,
    lineHeightHeading4: 1.3,
    lineHeightHeading5: 1.3,
    fontSize: 16,
    lineHeight: 1.3,
};

const colors: ThemeConfig['token'] = {
    colorPrimary: COLOUR.PRIMARY.GRAY,
    colorError: COLOUR.PRIMARY.RED,
    controlItemBgActive: COLOUR.SECONDARY.YELLOW,
    colorBgSolid: COLOUR.SECONDARY.GRAY,
};

const spacing: ThemeConfig['token'] = {
    padding: 16,
    paddingLG: 32,
    paddingXS: 8,
    // height of the basic controls such as buttons and input boxes
    controlHeight: 48,
    controlPaddingHorizontal: 18,
    paddingSM: 18,
    marginLG: 12,
};

const border: ThemeConfig['token'] = {
    borderRadius: 5,
    borderRadiusLG: 10,
    colorBorder: COLOUR.GRAYSCALE.GRAY2,
    colorBorderSecondary: COLOUR.GRAYSCALE.GRAY0,
    colorSplit: COLOUR.GRAYSCALE.GRAY1,
};

const select: ThemeConfig['components'] = {
    Select: {
        activeBorderColor: COLOUR.GRAYSCALE.GRAY2,
        activeOutlineColor: 'transparent',
        hoverBorderColor: COLOUR.GRAYSCALE.GRAY2,
        optionActiveBg: COLOUR.GRAYSCALE.GRAY0,
        optionSelectedBg: COLOUR.SECONDARY.YELLOW,
    },
};

const form: ThemeConfig['components'] = {
    Form: {
        itemMarginBottom: 24,
    },
};

// component token here
const button: ThemeConfig['components'] = {
    Button: {
        defaultShadow: 'none',
        primaryShadow: 'none',
        dangerShadow: 'none',
        contentFontSizeSM: 14,
        contentFontSize: 16,
        contentFontSizeLG: 18,
    },
};

const calendar: ThemeConfig['components'] = {
    Calendar: {
        colorText: COLOUR.SECONDARY.GRAY,
    },
};

const config: ThemeConfig = {
    components: {
        ...form,
        ...button,
        ...select,
        ...calendar,
    },
    token: {
        ...typography,
        ...spacing,
        ...border,
        ...colors,
    },
};

export default config;
