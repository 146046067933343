import React from 'react';
import { useParams } from 'react-router';
import { Flex, Form, Input, theme } from 'antd';
import Text from '~/components/typography/Text';
import { strings } from '~/feature/externalBooking/constants/strings';
import Link from 'antd/es/typography/Link';
import { BookingFieldType as FieldType } from '~/pages/BookingPage/EventFormPage';
import Title from '~/components/typography/Title';
import { useExternalBookingContext } from '~/feature/externalBooking/context/ExternalBookingContext';
import Loader from '~/components/Loader';
import PhoneNumberInput from './PhoneNumberInput';
import { emailValidator, phoneValidator } from '~/feature/externalBooking/modules';
import TrUsage from '~/modules/TrUsage';

const { TextArea } = Input;
const { useToken } = theme;
const MAX_CLIENT_NOTE_LENGTH = 300;

const EventDetailForm: React.FC = () => {
    const { groupId, trainerId, eventTypeId } = useParams();
    const { token } = useToken();
    const { isLoading, eventType } = useExternalBookingContext();

    React.useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        TrUsage.throwEvent(TrUsage.EVENT_ACTION.EXTERNAL_BOOKING_DETAILS_FORM, { groupId, trainerId, eventTypeId });
    }, [groupId, trainerId, eventTypeId]);

    return (
        <div>
            {isLoading && <Loader fullScreen />}
            <Title level={4} text={'Enter Details'} style={{ margin: '0px 0px 24px 0px' }} />
            <Flex style={{ maxWidth: 600 }} vertical>
                <Form.Item<FieldType>
                    label={strings.form.label.firstName}
                    name="clientFirstName"
                    rules={[{ required: true, message: strings.form.validation.firstName }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item<FieldType>
                    label={strings.form.label.lastName}
                    name="clientLastName"
                    rules={[{ required: true, message: strings.form.validation.lastName }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item<FieldType>
                    label={strings.form.label.email}
                    name="clientEmail"
                    rules={[{ required: true, message: strings.form.validation.email }, { validator: emailValidator }]}
                >
                    <Input />
                </Form.Item>
                {eventType?.actionInfo.isPhoneCall && (
                    <Form.Item<FieldType>
                        label={strings.form.label.phone}
                        name="clientPhone"
                        rules={[
                            { required: true, message: strings.form.validation.phone },
                            { validator: phoneValidator },
                        ]}
                    >
                        <PhoneNumberInput />
                    </Form.Item>
                )}
                <Form.Item<FieldType> label={strings.form.label.clientNote} name="clientNote">
                    <TextArea
                        count={{
                            show: false,
                            max: MAX_CLIENT_NOTE_LENGTH,
                            exceedFormatter: (txt, { max }) => txt.slice(0, max),
                        }}
                    />
                </Form.Item>
            </Flex>
            <div style={{ marginBottom: 36 }}>
                <Text size="xsmall" useSpan text={strings.form.agreement[0]} />
                <Link
                    style={{
                        color: token.colorText,
                        fontWeight: 500,
                        textDecoration: 'underline',
                        fontSize: 12,
                    }}
                    href="https://www.trainerize.com/endUserTerms/"
                    target="_blank"
                >
                    {strings.form.agreement[1]}
                </Link>
                <Text size="xsmall" useSpan text={strings.form.agreement[2]} />
                <Link
                    style={{
                        color: token.colorText,
                        fontWeight: token.fontWeightStrong,
                        textDecoration: 'underline',
                        fontSize: 12,
                    }}
                    href="https://www.trainerize.com/privacy/"
                    target="_blank"
                >
                    {strings.form.agreement[3]}
                </Link>
            </div>
        </div>
    );
};

export default EventDetailForm;
