import React from 'react';
import { Select as ANTSelect, SelectProps } from 'antd';
import './_style.scss';

interface ISelect extends SelectProps {
    children: React.ReactNode | React.ReactNode[] | JSX.Element | false | null;
}

const Select: React.FC<ISelect> = props => {
    const { children, ...rest } = props;

    return (
        <ANTSelect
            dropdownAlign={{
                offset: [0, 0],
            }}
            dropdownStyle={{
                padding: 0,
                ...props.dropdownStyle,
            }}
            {...rest}
        >
            {children}
        </ANTSelect>
    );
};

export const Option = ANTSelect.Option;
export const OptGroup = ANTSelect.OptGroup;

export default Select;
