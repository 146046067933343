import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { ICONS, ICON_COLORS } from '../../constant/icons.mjs';
import { FC, IconProps, SIZE } from './constants/types';

const buildViewBox = (elem: SVGSVGElement) => {
    const transform = elem.getAttribute('transform');
    const reg = /\((.*?)\)/g;
    const match = transform && reg.exec(transform);
    let viewBox = '0 0';
    if (match) {
        viewBox = match[1].length > 3 ? match[1] : match[1] + ' 0';
    }
    return `${viewBox} ${ARTBOARD_SIZE} ${ARTBOARD_SIZE}`;
};

const ARTBOARD_SIZE = 48;
const svgStyle: React.CSSProperties = {
    transform: 'translateZ(0)' /*for older browsers*/,
    willChange: 'transform',
};

const Icon: FC<IconProps> = props => {
    const {
        icon = ICONS.filled.appointments,
        color = ICON_COLORS.GRAY2,
        size = SIZE.medium,
        className = '',
        style = {},
        clickable,
        disabled,
        secondary,
        title,
        onClick = () => void 0,
    } = props;
    const [viewBox, setViewBox] = useState(`0 0 ${ARTBOARD_SIZE} ${ARTBOARD_SIZE}`);
    const [iconX, setIconX] = useState(0);
    const [iconY, setIconY] = useState(0);

    useEffect(() => {
        calculateViewBox();
    }, [icon]);

    const calculateViewBox = () => {
        const elem = document.getElementById(icon) as SVGSVGElement | null;
        if (elem) {
            const { x = 0, y = 0, height = 0, width = 0 } = elem.getBBox();
            const calcX = x - (ARTBOARD_SIZE - width) / 2;
            const calcY = y - (ARTBOARD_SIZE - height) / 2;
            setViewBox(buildViewBox(elem));
            setIconX(calcX < 0 ? 0 : calcX);
            setIconY(calcY < 0 ? 0 : calcY);
        }
    };

    const clickClass = clickable ? 'clickable' : '';
    const dimensions = { height: size, width: size };
    const disabledClass = disabled ? 'disabled' : '';
    const secondaryClass = secondary ? 'icon--secondary' : '';
    const hexValue = color.replace('#', '');
    const svgClass = classNames('icon svg', clickClass, className, disabledClass, secondaryClass);

    return (
        <svg
            name={icon}
            className={svgClass}
            viewBox={viewBox}
            preserveAspectRatio="none"
            style={{ ...svgStyle, ...dimensions, ...style }}
            onClick={onClick}
        >
            {!!title && <title>{title}</title>}
            <g transform={`translate(-${iconX}, -${iconY})`}>
                <use href={`#${icon}`} style={{ fill: `#${hexValue}` }}></use>
            </g>
        </svg>
    );
};

Icon.icon = ICONS;
Icon.color = ICON_COLORS;
Icon.size = SIZE;

export default Icon;
