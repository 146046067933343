import { createBrowserRouter } from 'react-router';
import BookingOutlet from './pages/BookingPage';
import NotFoundPage from './pages/NotFoundPage';
import EventListPage from './pages/BookingPage/EventListPage';
import EventBookingFormPage from './pages/BookingPage/EventFormPage';
import EventCancelPage from './pages/BookingPage/EventCancelPage';
import BaseRoute from './components/BaseRoute';

const router = createBrowserRouter(
    [
        {
            path: '/',
            element: <BaseRoute />,
            children: [
                {
                    path: 'booking/',
                    element: <BookingOutlet />,
                    children: [
                        { path: ':groupId/:trainerId', element: <EventListPage /> },
                        { path: ':groupId/:trainerId/:eventTypeId', element: <EventBookingFormPage /> },
                        { path: 'cancel/:eventGUID', element: <EventCancelPage /> },
                    ],
                },
            ],
        },
        {
            path: '*',
            element: <NotFoundPage />,
        },
    ],
    { basename: '/app' },
);

export default router;
