type ActionInfo = {
    isVideoCall: boolean;
    isPhoneCall: boolean;
};

export type ExternalBookingType = {
    appointmentTypeID: number;
    name: string;
    duration: number;
    isVirtual: boolean;
    actionInfo: {
        isVideoCall: boolean;
        isPhoneCall: boolean;
    };
    description?: string;
};

export type ExternalBookingRequest = {
    trainerID: number;
    appointmentTypeID: number;
    startTime: string;
    locationID?: number;
    clientFirstName: string;
    clientLastName: string;
    clientEmail: string;
    clientPhone?: string;
    clientNote?: string;
    clientVerifyToken: string; // token from recaptcha
};

export type AppointmentTypeListResponse = {
    code: number;
    message: string;
    count: number;
    appointmentTypes: ExternalBookingType[];
};

export type AppointmentTypeResponse = {
    code: number;
    message: string;
    appointmentType: ExternalBookingType;
};

export type ExternalBookingResponse = {
    code: number;
    message: string;
    startTime: string;
    endTime: string;
};

export type TrainerInfoResponse = {
    firstName: string;
    lastName: string;
};

export type Location = {
    locationID: number;
    name: string;
    address1: string;
    address2?: string;
    city: string;
    state: string;
    country: string;
    zipCode: string;
    phoneNumber: string;
};

export type LocationsResponse = {
    locations: Location[];
    count: number;
};

export type AvailableTimeSlotsResponse = {
    dailyCount: number;
    dailyTimeslots: AvailableTimeSlotByUTCDate;
};
export type AvailableTimeSlotByUTCDate = Record<string, AvailableTimeSlots>;
export type AvailableTimeSlots = {
    timeslots: string[];
    availableCount: number;
};

export type BookedEventDetail = {
    startDate: string;
    endDate: string;
    appointmentTypeName: string;
    appointmentTypeIsVirtual: boolean;
    appointmentTypeActionInfo: ActionInfo;
    appointmentTypeDuration: number;
    locationName: string;
    locationAddress1: string;
    locationAddress2?: string;
    appointmentTypeDescription: string;
    trainerFirstName: string;
    trainerLastName: string;
};

export type BookingEventResponse = {
    code: number;
    message: string;
    externalBooking: BookedEventDetail;
};

export enum ErrorCode {
    recaptcha = 1000,
    accountAlreadyExist = 1001,
    appointmentConflict = 2100,
}
