import React from 'react';
import EventInfo from '~/feature/externalBooking/ui/eventTypeForm/EventInfo';
import Button from '~/components/Button';
import { strings } from '~/feature/externalBooking/constants/strings';
import Text from '~/components/typography/Text';
import { BookingResultEvent } from '../eventTypeForm/BookingResult';
import Card from '~/components/Card';
import useWindowMediaQuery from '../../hooks/useWindowMediaQuery';
import { ErrorType } from '~/modules/api/types';

const styles: Record<string, React.CSSProperties> = {
    card: {
        maxWidth: 900,
    },
    container: {
        minWidth: 600,
    },
    label: {
        margin: '30px 0',
    },
    error: {
        marginTop: 24,
    },
};

type Props = {
    handleCancelEvent: () => void;
    error?: ErrorType;
    event?: BookingResultEvent;
};

const CancelForm: React.FC<Props> = ({ handleCancelEvent, error, event }) => {
    const { applyMinMediaQuery } = useWindowMediaQuery();
    const { trainerInfo, startDate, locationName } = event || {};
    if (!event) {
        return null;
    }
    return (
        <Card
            hideBorderOnSmallDevice
            style={{ ...styles.card, ...(applyMinMediaQuery ? undefined : styles.container) }}
        >
            <EventInfo
                appointmentType={event.eventType}
                trainerName={`${trainerInfo?.firstName} ${trainerInfo?.lastName}`}
                startTime={startDate}
                locationName={locationName}
            />
            <Text style={styles.label} text={strings.cancel.cancelEvent} />
            <Button type="primary" onClick={handleCancelEvent}>
                {strings.cancel.button}
            </Button>
            {error?.message && <Text size="small" type="danger" text={error.message} style={styles.error} />}
        </Card>
    );
};

export default CancelForm;
