import mixpanel, { Dict } from 'mixpanel-browser';
import { Fn } from '~/constant/types';
let _isMixpanelLoaded = false;

/**
 * Checks if mixpanel is loaded
 * @returns boolean
 */
const isMixpanelLoaded = (): boolean => _isMixpanelLoaded;

export const initMixpanel = (onInit?: Fn): void => {
    if (isMixpanelLoaded()) {
        return;
    }
    const MIXPANEL_KEY = window.TZ_ME.Globals.mixpanel_key;
    window.mixpanel = mixpanel;
    mixpanel.init(MIXPANEL_KEY, {
        loaded: function (_mixpanel) {
            _isMixpanelLoaded = !!_mixpanel.get_distinct_id();
            if (_isMixpanelLoaded && onInit) {
                onInit();
            }
        },
    });
};

export const setupMixpanelSession = (): void => {
    mixpanel.identify();
    mixpanel.people.set({ userType: 'visitor' });
};

export const trackMixpanelEvent = (eventAction: string, extraParams?: Dict): void => {
    const from = 'tzmeSpa';
    if (mixpanel) {
        const eventName = eventAction + ' - ' + from;
        mixpanel.track(eventName, extraParams);
    }
};
