import { add, endOfDay, isAfter, isBefore, startOfDay, sub } from 'date-fns';
import { convertToUtc, utc } from '~/modules/utils/datetime';
import { MAX_BOOKING_WINDOW } from '../constants';

export const allowMonthChange = (nextDate: Date) => {
    const today = startOfDay(new Date());
    const minDate = sub(today, { months: 1 });
    const maxDate = add(today, { months: MAX_BOOKING_WINDOW });
    return (
        (minDate.getFullYear() === nextDate.getFullYear() || maxDate.getFullYear() === nextDate.getFullYear()) &&
        minDate.getMonth() < nextDate.getMonth() &&
        maxDate.getMonth() > nextDate.getMonth()
    );
};

export const isOutOfRange = (date: Date) => {
    const minDate = startOfDay(new Date());
    const maxDate = add(minDate, { months: MAX_BOOKING_WINDOW });
    return isBefore(date, minDate) || isAfter(date, maxDate);
};

export const handlesDST = (
    minBookingDateTime: string, // in UTC
    maxBookingDateTime: string, // in UTC
): Array<{ startDateTime: string; endDateTime: string }> => {
    let shouldHandleDST = false;

    let transitionTime = utc(minBookingDateTime);

    while (isBefore(transitionTime, maxBookingDateTime)) {
        const nextLocalTime = add(transitionTime, { days: 1 });
        // Get the UTC offset for both local time and next local time
        const offsetLocalTime = transitionTime.getTimezoneOffset();
        const offsetNextLocalTime = nextLocalTime.getTimezoneOffset();
        // Check if the offsets are different, if yes there is a DST transition
        if (offsetLocalTime !== offsetNextLocalTime) {
            shouldHandleDST = true;
            break;
        }
        transitionTime = add(transitionTime, { days: 1 });
    }
    const dates = shouldHandleDST
        ? [
              {
                  startDateTime: minBookingDateTime,
                  endDateTime: convertToUtc(endOfDay(transitionTime)),
              },
              {
                  startDateTime: convertToUtc(add(transitionTime, { days: 1 })),
                  endDateTime: maxBookingDateTime,
              },
          ]
        : [{ startDateTime: minBookingDateTime, endDateTime: maxBookingDateTime }];

    return dates;
};
