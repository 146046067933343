import React from 'react';
import { Card as AntCard, CardProps } from 'antd';
import useWindowMediaQuery from '~/feature/externalBooking/hooks/useWindowMediaQuery';
import { createStyles } from 'antd-style';
import classNames from 'classnames';

type Props = CardProps & {
    hideBorderOnSmallDevice?: boolean;
    hideShadow?: boolean;
};
const useStyle = createStyles(({ prefixCls, css }) => ({
    cardStyle: css`
        &.${prefixCls}-card {
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
        }
    `,
    noBoxShadow: css`
        &.${prefixCls}-card:not(.${prefixCls}-card-bordered) {
            box-shadow: none;
        }
    `,
}));

const getCardSize = (size: CardProps['size'], applyMinMediaQuery: boolean) => {
    if (size) {
        return size;
    }
    return applyMinMediaQuery ? 'small' : 'default';
};

const getVariantValue = (
    applyMinMediaQuery: boolean,
    hideBorderOnSmallDevice?: boolean,
    variant?: CardProps['variant'],
) => {
    if (variant) {
        return variant;
    }
    return applyMinMediaQuery && hideBorderOnSmallDevice ? 'borderless' : 'outlined';
};

const Card: React.FC<Props> = ({ hideBorderOnSmallDevice, variant, hideShadow, size, ...rest }) => {
    const { styles } = useStyle();
    const { applyMinMediaQuery } = useWindowMediaQuery();
    const cardVariant = getVariantValue(applyMinMediaQuery, hideBorderOnSmallDevice, variant);
    const cardSize = getCardSize(size, applyMinMediaQuery);
    const cardClass = classNames({
        [styles.cardStyle]: !hideShadow,
        [styles.noBoxShadow]: hideBorderOnSmallDevice,
    });

    return <AntCard size={cardSize} className={cardClass} variant={cardVariant} {...rest} />;
};

export default Card;
