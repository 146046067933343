import React from 'react';
import Button from '~/components/Button';
import Icon from '~/components/Icon';

type handleGoBack = {
    handleGoBack: () => void;
};

const GoBackButton: React.FC<handleGoBack> = ({ handleGoBack }) => (
    <Button
        icon={Icon.icon.outlined.arrowBack}
        iconColor={Icon.color.GRAY5}
        iconOnly
        type="primary"
        iconSize={18}
        btnColor={Icon.color.yellow}
        onClick={handleGoBack}
        style={{ marginBottom: 36 }}
    />
);

export default GoBackButton;
