import { CSSProperties, FC } from 'react';
import { AlertProps, Alert as ANTAlert, ConfigProvider, theme } from 'antd';

const { useToken } = theme;

const styles: Record<string, CSSProperties> = {
    alert: {
        width: '100%',
        textAlign: 'center',
    },
};

const Alert: FC<AlertProps> = props => {
    const { token } = useToken();

    const alertStyle = {
        ...props.style,
        ...styles.alert,
    };

    return (
        <ConfigProvider
            theme={{
                components: {
                    Alert: {
                        colorText: props.type === 'error' ? token.colorError : token.colorText,
                        fontSize: 14,
                    },
                },
            }}
        >
            <ANTAlert style={alertStyle} {...props} />
        </ConfigProvider>
    );
};

export default Alert;
