import { FC } from 'react';
import classNames from 'classnames';
import { createStyles } from 'antd-style';

const useStyle = createStyles(({ token, css }) => {
    return {
        loader: css`
            height: 20px;
            width: 20px;
            position: relative;
            flex-shrink: 0;
            &:before {
                content: '';
                box-sizing: border-box;
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;
                height: 100%;
                margin-top: -50%;
                margin-left: -50%;
                border-radius: 50%;
                border-top: 2px solid ${token.colorBorder};
                border-right: 2px solid transparent;
                animation: spinner 0.6s linear infinite;
            }
            @keyframes spinner {
                to {
                    transform: rotate(360deg);
                }
            }
        `,
        loaderWhite: css`
            &:before {
                border-top: 2px solid ${token.colorTextLightSolid};
            }
        `,
        loaderBlack: css`
            &:before {
                border-top: 2px solid ${token.colorText};
            }
        `,
        loaderOverlay: css`
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(255, 255, 255, 0.75);
            z-index: 5;
        `,
        flexCenter: css`
            display: flex;
            justify-content: center;
            align-items: center;
        `,
        fullScreen: css`
            width: 100%;
            height: 100%;
        `,
        fullWidth: css`
            width: 100%;
        `,
    };
});

type Props = {
    fullScreen?: boolean;
    fullWidth?: boolean;
    black?: boolean;
    white?: boolean;
    className?: string;
};

const Loader: FC<Props> = ({ fullScreen, fullWidth, black, white, className }) => {
    const { styles } = useStyle();
    const wrapStyle = classNames(styles.flexCenter, {
        [styles.loaderOverlay]: fullScreen,
        [styles.fullScreen]: fullScreen,
        [styles.fullWidth]: fullWidth,
    });
    const loaderClass = classNames(
        styles.loader,
        {
            [styles.loaderBlack]: black,
            [styles.loaderWhite]: white,
        },
        className,
    );

    return (
        <div className={wrapStyle}>
            <div className={loaderClass}></div>
        </div>
    );
};

export default Loader;
