import { FC } from 'react';
import { Flex } from 'antd';
import Text from '~/components/typography/Text';
import Select, { Option } from '~/components/Select';
import { locationSelect as strings } from '~/feature/externalBooking/constants/strings';
import { useExternalBookingContext } from '../../context/ExternalBookingContext';

const LocationSelect: FC = () => {
    const { isLoading, locations, locationId, setLocationId } = useExternalBookingContext();

    return (
        <Flex gap={8} vertical>
            <Text text={strings.title} />
            <Select
                value={locationId}
                onChange={value => setLocationId(value)}
                placeholder={strings.placeholder}
                notFoundContent={strings.noData}
                loading={isLoading}
            >
                {locations.map(location => (
                    <Option key={location.locationID} value={location.locationID}>
                        {location.name}
                    </Option>
                ))}
            </Select>
        </Flex>
    );
};

export default LocationSelect;
