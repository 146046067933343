import React from 'react';
import { useParams } from 'react-router';
import { Flex, Form, FormProps } from 'antd';
import { BookingFieldType } from '~/pages/BookingPage/EventFormPage';
import EventDetailForm from './EventDetailForm';
import EventTimeForm from './EventTimeForm';
import GoBackButton from './GoBackBtn';
import EventInfo from './EventInfo';
import Card from '~/components/Card';
import { useExternalBookingContext } from '../../context/ExternalBookingContext';
import { getLocationByID } from '../../modules/selectors';
import { ErrorCode, ExternalBookingRequest } from '../../constants/types';
import { formatAddress, formatPhoneNumber, getErrorMsg } from '../../modules';
import Button from '~/components/Button';
import { strings } from '~/feature/externalBooking/constants/strings';
import Text from '~/components/typography/Text';
import useWindowMediaQuery from '../../hooks/useWindowMediaQuery';
import FooterLogo from '~/components/FooterLogo';
import ReCAPTCHA from 'react-google-recaptcha';

const customizeRequiredMark = (label: React.ReactNode, { required }: { required: boolean }) => (
    <>
        {label}
        <span>{required ? '*' : null}</span>
    </>
);

const styles: Record<string, React.CSSProperties> = {
    container: {
        marginTop: 32,
    },
    card: {
        maxWidth: 900,
    },
    mobileCard: {
        width: '100%',
    },
    btnWrapper: {
        width: '100%',
    },
    btn: {
        minWidth: 200,
        marginTop: 30,
    },
    hiddenButton: {
        visibility: 'hidden',
        height: 0,
    },
};

const EventBookingForm: React.FC = () => {
    const [step, setStep] = React.useState(1);
    const {
        trainerInfo,
        eventType,
        locations,
        appointmentTime,
        locationId,
        error,
        completeBooking,
        setAppointmentTime,
        setError,
    } = useExternalBookingContext();
    const { trainerId, eventTypeId } = useParams();
    const { applyMinMediaQuery } = useWindowMediaQuery();
    const reCaptchaRef = React.createRef<ReCAPTCHA>();
    const [isRecaptchaLoaded, setIsRecaptchaLoaded] = React.useState(false);
    const [form] = Form.useForm();
    const RECAPTCHA_SITE_KEY = window.TZ_ME.Globals.recaptcha_site_key;
    const buttonRef = React.useRef<HTMLButtonElement>(null);

    const handleGoBack = () => {
        setStep(1);
        // reset appointment time
        if (error?.code === ErrorCode.appointmentConflict) setAppointmentTime();
        // reset error message
        if (error) setError();
    };

    const getLocationName = () => {
        if (step === 1) return undefined;
        const selectedLocation = getLocationByID(locations, locationId);
        return selectedLocation && formatAddress(selectedLocation);
    };

    const scrollToButton = () => {
        buttonRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const handleSubmit: FormProps<BookingFieldType>['onFinish'] = () => {
        if (step === 1) {
            setStep(2);
        } else {
            reCaptchaRef.current?.execute();
        }
    };

    const asyncScriptOnLoad = () => {
        setIsRecaptchaLoaded(true);
    };

    const handleRecaptcha = (token: string | null) => {
        const values = form.getFieldsValue();
        const { clientPhone, ...restValues } = values;
        if (token) {
            if (eventType && trainerId && eventTypeId && appointmentTime) {
                const data: ExternalBookingRequest = {
                    ...restValues,
                    trainerID: parseInt(trainerId),
                    startTime: appointmentTime,
                    appointmentTypeID: parseInt(eventTypeId),
                    locationID: eventType.isVirtual ? undefined : locationId,
                    clientPhone: eventType.isVirtual && clientPhone ? formatPhoneNumber(clientPhone) : undefined,
                    clientVerifyToken: token,
                };
                completeBooking(data);
            } else {
                setError({ message: getErrorMsg() });
            }
        } else {
            handleRecaptchaError();
        }
        reCaptchaRef.current?.reset();
    };

    const handleRecaptchaError = () => {
        setError({ message: getErrorMsg(ErrorCode.recaptcha) });
    };

    return (
        <>
            <Card style={applyMinMediaQuery ? styles.mobileCard : styles.card} hideBorderOnSmallDevice>
                {step === 2 && <GoBackButton handleGoBack={handleGoBack} />}
                {eventType && trainerInfo && (
                    <EventInfo
                        appointmentType={eventType}
                        trainerName={`${trainerInfo.firstName} ${trainerInfo.lastName}`}
                        {...(step === 2 ? { startTime: appointmentTime, locationName: getLocationName() } : {})}
                    />
                )}
                <Flex gap="large" vertical style={styles.container}>
                    <Form
                        name="externalBooking"
                        layout="vertical"
                        onFinish={handleSubmit}
                        requiredMark={customizeRequiredMark}
                        form={form}
                    >
                        {step === 1 ? <EventTimeForm onSelectTime={scrollToButton} /> : <EventDetailForm />}
                        <Form.Item style={{ ...(!appointmentTime ? styles.hiddenButton : {}) }}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{
                                    ...styles.btn,
                                    ...(applyMinMediaQuery ? styles.btnWrapper : {}),
                                }}
                                disabled={!isRecaptchaLoaded}
                                ref={buttonRef}
                            >
                                {step === 1 ? strings.continueCTA : strings.form.submit}
                            </Button>
                        </Form.Item>
                        {error?.message && <Text size="small" useSpan type="danger" text={error?.message} />}
                    </Form>
                </Flex>
                <FooterLogo />
            </Card>
            <ReCAPTCHA
                size="invisible"
                ref={reCaptchaRef}
                sitekey={RECAPTCHA_SITE_KEY}
                onChange={handleRecaptcha}
                asyncScriptOnLoad={asyncScriptOnLoad}
                onErrored={handleRecaptchaError}
                onExpired={handleRecaptchaError}
            />
        </>
    );
};

export default EventBookingForm;
