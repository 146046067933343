import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { CalendarProps, Col, Row } from 'antd';
import { add, formatDate, isSameDay, startOfDay, sub } from 'date-fns';
import { createStyles } from 'antd-style';
import { APIDateTimeFormat, DisplayMonthYearFormat } from '~/modules/utils/datetime';
import Text from '~/components/typography/Text';
import Icon from '~/components/Icon';
import Loader from '~/components/Loader';
import Card from '../Card';
import useWindowMediaQuery from '~/feature/externalBooking/hooks/useWindowMediaQuery';
import { allowMonthChange, isOutOfRange } from '~/feature/externalBooking/modules/datesHelper';
import { datePicker as strings } from './constants/strings';
import Calendar from './calendar';
import './scss/_styles.scss';

interface ICalendar extends CalendarProps<Date> {
    isLoading: boolean;
    selectedDate?: Date;
    highlightDates?: string[];
    onSelectDate: (date: Date) => void;
}

const useStyle = createStyles(({ token, css }) => {
    return {
        rightArrow: css`
            padding-left: 48px;
        `,
        leftArrow: css`
            padding-right: 48px;
        `,
        dateCell: css`
            position: relative;
            z-index: 2;
            display: inline-block;
            min-width: 36px;
            height: 36px;
            line-height: 36px;
            border-radius: ${token.borderRadius}px;
            transition: background 0.2s;
            pointer-events: auto;
            &:hover {
                background: rgba(0, 0, 0, 0.04);
            }
        `,
        today: css`
            border: 1px solid ${token.colorBorder};
        `,
        current: css`
            color: ${token.colorTextLightSolid};
            background: ${token.colorPrimary};
            border-color: transparent;
            &:hover {
                background: ${token.colorPrimary};
                opacity: 0.8;
            }
        `,
        highlighted: css`
            color: ${token.colorTextTertiary};
            background: ${token.controlItemBgActive};
            font-weight: 700;
            &:hover {
                background: ${token.controlItemBgActive};
                opacity: 0.8;
            }
        `,
        hide: css`
            display: none;
        `,
        outOfRange: css`
            color: ${token.colorTextDisabled};
        `,
        datePickerWrapper: css`
            position: relative;
        `,
        nullScreenWrapper: css`
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 3;
            display: flex;
            align-items: center;
            justify-content: center;
        `,
        card: css`
            box-shadow: 0 0 10px ${token.colorBorder};
        `,
    };
});

const today = new Date();

const DatePicker: React.FC<ICalendar> = props => {
    const { styles } = useStyle();
    const { applyMinMediaQuery } = useWindowMediaQuery();
    const { isLoading, selectedDate, highlightDates, onSelectDate } = props;
    const [panelDate, setPanelDate] = React.useState<Date>(today);
    const [currentDate, setCurrentDate] = useState(today);

    const onPanelChange: CalendarProps<Date>['onPanelChange'] = date => {
        setPanelDate(date);
    };

    const onDateSelect: CalendarProps<Date>['onSelect'] = (date, selectInfo) => {
        if (selectInfo.source === 'date' && !isOutOfRange(date)) {
            onSelectDate(date);
        }
    };

    useEffect(() => {
        // To change the month when the first available date is preselected
        if (selectedDate) {
            setCurrentDate(selectedDate);
            setPanelDate(selectedDate);
        }
    }, [selectedDate]);

    // Customize date cell render
    const dateCellRender: CalendarProps<Date>['fullCellRender'] = (date, info) => {
        const isSelected = selectedDate && isSameDay(date, selectedDate);
        const isToday = isSameDay(date, today);
        const isOutsideOfMonth = date.getMonth() !== panelDate.getMonth();
        const dateString = formatDate(startOfDay(date), APIDateTimeFormat);
        const isHighlighted = !isSelected && highlightDates?.includes(dateString);

        if (info.type === 'date') {
            return React.cloneElement(info.originNode, {
                ...(info.originNode as React.ReactElement<HTMLDivElement>).props,
                className: classNames({
                    [styles.dateCell]: !isOutsideOfMonth,
                    [styles.current]: isSelected,
                    [styles.today]: isToday,
                    [styles.highlighted]: isHighlighted,
                    [styles.hide]: isOutsideOfMonth,
                    [styles.outOfRange]: isOutOfRange(date),
                }),
                onClick: () => onDateSelect(date, { source: 'date' }),
            });
        }
    };

    const headerRender = (config: { value: Date; onChange: (date: Date) => void }) => {
        const { value, onChange } = config;
        const monthName = formatDate(value, DisplayMonthYearFormat);
        const prevMonthDate = sub(value, { months: 1 });
        const nextMonthDate = add(value, { months: 1 });
        const allowGoPrev = allowMonthChange(prevMonthDate);
        const allowGoNext = allowMonthChange(nextMonthDate);
        // Render a custom header with month name, and custom navigation buttons

        const handleChange = (date: Date) => {
            onChange(date);
            setCurrentDate(date);
        };

        return (
            <Row gutter={[0, 14]} align="middle" style={{ marginBottom: 14 }}>
                <Col md={{ span: 4 }} span={24}>
                    <Text text={strings.label} fontWeight={500} size="xlarge" center={applyMinMediaQuery} />
                </Col>
                <Col md={{ span: 20, pull: 2 }} span={24}>
                    <Row align="middle" justify="center">
                        <Icon
                            icon={Icon.icon.outlined.leftArrow}
                            size={Icon.size.xlarge}
                            color={allowGoPrev ? Icon.color.GRAY5 : Icon.color.GRAY2}
                            className={styles.leftArrow}
                            clickable
                            onClick={() => allowGoPrev && handleChange(prevMonthDate)}
                        />
                        <Text text={monthName} fontWeight={300} size="xlarge" center />
                        <Icon
                            icon={Icon.icon.outlined.rightArrow}
                            size={Icon.size.xlarge}
                            color={allowGoNext ? Icon.color.GRAY5 : Icon.color.GRAY2}
                            className={styles.rightArrow}
                            clickable
                            onClick={() => allowGoNext && handleChange(nextMonthDate)}
                        />
                    </Row>
                </Col>
            </Row>
        );
    };

    const renderNullScreen = () => {
        return (
            <div className={styles.nullScreenWrapper}>
                <Card className={styles.card}>
                    <Text text={strings.noAvailability} type="secondary" center />
                </Card>
            </div>
        );
    };

    return (
        <div className={styles.datePickerWrapper}>
            {isLoading && <Loader fullScreen />}
            {highlightDates?.length === 0 && renderNullScreen()}
            <Calendar
                value={currentDate}
                fullscreen={false}
                headerRender={headerRender}
                fullCellRender={dateCellRender}
                onSelect={onDateSelect}
                onPanelChange={onPanelChange}
                {...props}
            />
        </div>
    );
};

export default DatePicker;
